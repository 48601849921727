.accordion {
  background-color: var(--black) !important;
  padding: 40px !important;
}
.accordion-button {
  color: var(--golden) !important;
  background-color: var(--black) !important;
  border-bottom: 1px solid var(--black) !important;
  font-family: HS_Regular !important;
  font-size: 20px;
}
.accordion {
  --bs-accordion-btn-icon: url("../../assets/images/down-chevron.svg") !important;
  --bs-accordion-btn-active-icon: url("../../assets/images/down-chevron.svg") !important;
  --bs-accordion-border-color: none !important;
  --bs-accordion-btn-focus-border-color: none !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}
.accordion-item {
  border-bottom: 2px solid var(--golden-dark) !important;
  background-color: var(--black) !important;
  padding: 10px 0;
}
.accordion-body {
  background-color: var(--black) !important;
  color: var(--golden) !important;
  word-spacing: 4px;
  font-family: HS_Light;
  font-size: 15px;
  line-height: 1.6;
}
.about_title {
  text-align: center !important;
  font-family: HS_Bold;
  margin-bottom: 0;
}
