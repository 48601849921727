.footer {
  background-color: var(--charcoal);
  color: var(--golden);
  width: 100%;
}
.footer-section {
  padding: 30px 20px 20px 20px;
}
.web-info img {
  width: 170px;
  margin-bottom: 10px;
}
.web-info p {
  font-family: HS_Light;
}
.connect h6 {
  margin: 0;
}
.caret {
  width: 10px;
  margin-left: 5px;
}
.connect ul {
  list-style-type: none;
  padding-left: 0;
  font-family: HS_Light;
  margin-top: 20px;
}
a {
  color: var(--golden) !important;
  text-decoration: none !important;
}
.connect ul li a {
  color: var(--golden) !important;
  text-decoration: none !important;
}
.recieve-updates p {
  font-family: HS_Light;
}
.recieve-updates .join {
  border: 1px solid var(--golden-dark);
  display: flex;
  max-width: 300px;
}
.recieve-updates input {
  border: none;
  outline: none;
  color: var(--golden);
  background-color: var(--charcoal);
  width: 100%;

  padding: 5px;
}
input::-webkit-input-placeholder {
  color: var(--golden) !important;
  opacity: 0.5;
}
.recieve-updates button {
  border: none;
  background-color: var(--golden);
  font-family: HS_Light;
  padding: 0 20px;
}
.recieve-updates button:hover {
  background-color: var(--black);
  color: var(--golden);
}
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--golden);
  height: 40px;
}
.copyright p {
  margin-bottom: 0;
  font-size: 12px;
  opacity: 0.8;
}
