.quick-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.quick-product-img {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.quick-product-img img {
}
.quick-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  color: var(--golden);
  cursor: pointer;
}
.quick-product-info {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  padding: 20px 0;
}
.quick-product-info p:first-child {
  font-size: 20px;
}
.quick-description {
  font-size: 12px;
}
.quick-modal-cart-btn {
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  margin-top: 20px;
  padding: 10px 15px;
  border: none;
}

@media only screen and (min-width: 480px) {
  .quick-wrapper {
    flex-direction: column !important;
  }
}
@media only screen and (min-width: 768px) {
  .quick-wrapper {
    gap: 10px;
    min-height: 300px;
  }
  .quick-product-img {
    flex: 1;
    background-size: 100%;
  }
  .quick-product-info {
    padding: 0;
    flex: 1;
  }
  .quick-close-icon {
    top: 0;
    right: 0;
  }
  .quick-description {
    font-size: 12px;
  }
}

@media only screen and (min-width: 992px) {
  .quick-wrapper {
    flex-direction: row !important;
    padding: 20px 40px;
    gap: 40px;
  }
  .quick-close-icon {
    width: 30px;
    height: 30px;
    top: 10px;
    right: 40px;
  }
  .quick-description {
    font-size: 14px;
  }
}
