
.contact-box {
  padding: 40px;
  background-color: var(--white);
}
.contact-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.contact-heading h2 {
  font-family: HS_Bold;
  margin-bottom: 0;
}
.underline-heading {
  height: 3px;
  width: 80px;
  background-color: var(--golden);
}
.input-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
}
.input-field label {
  font-family: HS_Light;
  margin-bottom: 5px;
}
.input-field input,
.input-field select,
.input-field textarea {
  width: 100%;
  outline: none;
  padding: 5px 5px;
  border-radius: 3px;
  border: 1px solid var(--black);
}
Select {
  height: 50px;
  position: relative;
}
.subject input {
  height: 50px;
}
.input-field input:hover,
.input-field input:focus,
.input-field textarea:hover,
.input-field textarea:focus,
.input-field select:hover,
.input-field select:focus {
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 3px;
}
.contact-btn {
  background-color: var(--black);
  color: var(--white);
  padding: 7px 40px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.6);
}



@media only screen and (min-width: 481px) {
  .contact {
    background-color: var(--very-light-grey);
  }
  .contact-container {
    padding: 60px 10px;
  }
  .contact-box {
    max-width: 450px;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.6);
  }
}

@media only screen and (min-width: 768px) {
  .contact-box {
    max-width: 700px;
    border-radius: 30px;
  }
  .name-email {
    display: flex;
    gap: 40px;
  }
}
