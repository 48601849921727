.nav_bar{
    background-color: var(--black);
    color: var(--golden-dark);
    width: 100% !important;
    font-size: 16px;
    position: sticky;
    top: 0 !important;
    position: -webkit-sticky;
    overflow: hidden;
    z-index: 9999;
    height: 90px;
   width: 100%;
   border-bottom-left-radius: 100px 100px;
   border-bottom-right-radius: 100px 100px;
}



.logo_img{
  height: 50px;
}

.ham_burger_menu{
    cursor: pointer;
    color: var(--golden-dark);
}

.hover-underline {
    display: inline-block;
    position: relative;
    color: var(--golden-dark);
  }
  
  .hover-underline::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2.5px;
    bottom: 0;
    left: 0;
    background-color: var(--golden-dark);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .link{
    margin-left: 50px;
  }

  
  @media only screen and (min-width: 481px) {
    .logo_img{
      height: 40px !important;
    }
    .nav_bar{
      height: 70px !important;
  }
  }