.section_title {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}
p {
  margin-bottom: 0 !important;
}


@media only screen and (min-width: 481px) {
  .section_title {
    font-size: 30px;
  }
 
}
