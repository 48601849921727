:root {
  --gray: #646d7a;
  --light-grey: #999999;
  --very-light-grey: #eee;
  --black: #000000;
  --white: #fff;
  --golden: #e8b660;
  --golden-dark: #c09851;
  --charcoal: #1e1d1d;
  --input-bg: #eefaf9;
  /* --input-bg: #EFF2FC; */
  --input-border: #4ae4dd;
}

@font-face {
  font-family: "HS_Light";
  src: url("./assets/fonts/HarmoniaSansProCyr-Light.ttf");
  /* Add additional src lines for other font file formats */
}

@font-face {
  font-family: "HS_Regular";
  src: url("./assets/fonts/HarmoniaSansProCyr-Regular.ttf");
  /* Add additional src lines for other font file formats */
}

@font-face {
  font-family: "HS_Bold";
  src: url("./assets/fonts/HarmoniaSansProCyr-Bold.ttf");
  /* Add additional src lines for other font file formats */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: HS_Regular, sans-serif;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 9px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: var(--very-light-grey); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--light-grey); /* color of the scroll thumb */
}

.form-control:focus {
  box-shadow: none !important;
}

input::-webkit-input-placeholder {
  color: var(--light-grey) !important;
}

input:-moz-placeholder {
  color: var(--light-grey) !important;
  font-size: 12px;
}
.underline-heading {
  height: 3px;
  width: 80px;
  background-color: var(--golden);
  margin: 0 auto;
}
.row {
  --bs-gutter-x: 0 !important;
}
