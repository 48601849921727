.container {
  max-width: 1000px !important;
}
.cart {
  padding: 20px;
}
.cart h3 {
  font-size: 20px;
  font-family: HS_Regular;
  margin-bottom: 0;
}
.continue-browsing {
  cursor: pointer;
}
.continue-browsing p {
  color: var(--black) !important;
  margin: 0 10px 0 0;
  font-family: HS_Light;
}
.chevron-right {
  width: 10px;
}
.empty-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.empty-cart p {
  font-size: 20px;
  font-family: HS_Bold;
  color: var(--light-grey);
}
.product-info {
  display: flex;
  gap: 20px;
  padding: 10px 0;
  position: relative;
}
.product-img {
  width: 100px !important;
  height: 100px;
  border: 1px solid var(--light-grey);
  overflow: hidden;
}
.product-info h6 {
  font-size: 18px;
  font-family: HS_Regular;
}
.product-info p {
  font-size: 14px;
  font-family: HS_Light;
}
.product-detail {
  display: flex;
  flex-direction: column;
}
.quantity {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70px;
  height: 30px;
  border: 1px solid var(--very-light-grey);
}
.quantity:hover {
  border: 1px solid var(--black);
}
.quantity p {
  font-size: 18px;
  font-family: HS_Light;
  margin-bottom: 0;
}
.quantity p:first-child,
.quantity p:last-child {
  cursor: pointer;
}
.minimum {
  cursor: initial !important;
  color: var(--light-grey);
}
.product-total-price {
  margin-right: 40px;
}
.product-total-price p {
  margin-bottom: 0;
  font-size: 16px;
}
.close {
  color: var(--gray);
  margin-top: 7px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}
.promo {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.promo p {
  font-family: HS_Light;
  margin-bottom: 0;
}
.promo-input-field {
  display: flex;
  border: 1px solid var(--black);
  max-width: 350px;
  margin-top: 15px;
}
.promo input {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
}
.promo input::-webkit-input-placeholder {
  color: var(--light-grey) !important;
  font-family: HS_Light;
}
.promo-btn {
  background-color: var(--white);
  border: none;
  border-left: 1px solid var(--black);
  padding: 0 20px;
  width: 100px;
}
.add-note {
  margin: 30px 0;
}
.add-note p {
  font-family: HS_Light;
  margin-bottom: 10px !important;
}
.add-note textarea {
  padding: 10px;
  outline: none;
  width: 100%;
  max-width: 350px;
}
.add-note textarea::-webkit-input-placeholder {
  color: var(--light-grey) !important;
  font-family: HS_Light;
}
.tag-icon {
  width: 20px;
  height: 20px;
}
.order-summary {
  padding: 20px;
}
.order-summary h4 {
  font-size: 20px;
  font-family: HS_Regular;
}
.subtotal p {
  font-family: HS_Light;
}
.delivery-question {
  font-family: HS_Light;
  font-size: 16px;
  margin-bottom: 5px !important;
}
.delivery-answer {
  margin-top: 10px;
  color: var(--golden-dark);
}
.select-shipment {
  word-spacing: 1.5px;
  margin-top: 10px;
  font-size: 14px;
  font-family: HS_Light;
  color: red;
}
select {
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 3px;
}
/* .estimate {
  font-family: HS_Light;
  cursor: pointer;
} */
.total {
  display: flex;
  justify-content: space-between;
}
.total p {
  font-size: 20px;
  margin-bottom: 10px !important;
  font-family: HS_Regular;
}
.checkout-btn button {
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  border: none;
  padding: 10px 15px;
  font-family: HS_Regular;
}
.checkout-btn button:disabled {
  background-color: var(--light-grey);
}
.secure {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.secure p {
  margin-bottom: 0;
  font-family: HS_Regular;
  font-size: 14px;
}
.lock {
  width: 10px;
  height: 10px;
  margin-top: -3px;
  margin-right: 5px;
}

.promo_error{
  border: 1px solid #e45353 !important;
  display: flex;
  max-width: 350px;
  margin-top: 15px;
}



@media only screen and (min-width: 992px) {
  .cart {
    padding-right: 50px !important;
  }
}
