.card {
  height: 400px !important;
  width: 250px !important;
  margin: 10px;
}
.card-text {
  color: var(--gray);
  font-size: 17px;
  height: 50px !important;
  overflow: hidden;
}
@media only screen and (min-width: 845px) {
  /* .card {
    margin: 0 50px;
  } */
}
/* .card {
  min-height: 550px !important;
  max-height: 550px !important;
  cursor: pointer;
  position: relative;
  border-radius: 0 !important;
}

.card-body {
  padding: 0 !important;
  height: 200px !important;
}

.card-text {
  color: var(--gray);
  font-size: 17px;
  height: 50px !important;
  overflow: hidden;
}

.card_price {
  font-weight: bold;
  color: var(--black);
  font-size: 18px;
}

.shop_cards_btn {
  width: 100%;
  border: none;
  color: var(--white);
  background-color: var(--black);
  padding: 6px 0;
  font-size: 18px;
  bottom: 0 !important;
}

.shop_cards_btn:hover {
  opacity: 0.8;
}


.img_container{
  width: 100% !important;
}

.card-img-top {
  max-height: 350px;
  min-height: 350px;
}


.card_title {
  display: table; */
/*margin: 0px auto 0px auto;*/ /* keep the table centered */
/* padding: 2px 10px;
  background-color: var(--black);
  font-size: 14px;
  font-weight: bold;
  color: var(--white);
  left: 0 !important;
  top: 0 !important;
  margin-bottom: 0;
  position: absolute;
}

.img_container {
  position: relative;
}

.details_btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  padding: 10px;
  opacity: 0.8;
  display: none;
}

.card:hover .details_btn {
  display: block;
}

@media only screen and (min-width: 576px) {
  .card {
    min-height: 400px !important;
    max-height: 400px !important;
    margin-right: 10px;
  }
  .card-img-top {
    max-height: 250px !important;
  }
} */
