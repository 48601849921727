.order-success {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56vh;
}
.success-container {
  width: 100%;
  max-width: 500px;
  padding: 40px;
  margin: 80px 40px;
  border-radius: 10px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.6);
}
.order-success img {
  max-width: 200px;
}
.fireworks {
  margin: 0 auto;
}
.order-success-msg {
  font-size: 18px;
  font-family: "HS_Regular";
  text-align: center;
}
.order-success-btn {
  border: none;
  background-color: var(--black);
  color: var(--golden);
  padding: 5px 20px 3px 20px;
  width: 100%;
  font-family: "HS_Regular";
  font-size: 16px;
  margin-top: 10px;
}
