.header_container {
  position: relative;
  padding: 0 20px !important;
}
.header_img {
  height: 100%;
  width: 100%;
  border-radius: 40px;
  margin: 40px 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 15px 25px, rgba(0, 0, 0, 0.3) 0px 5px 10px;
}

.header_text {
  color: var(--white);
  position: absolute;
  bottom: 10px;
  left: 5px;
  z-index: 99;
}

.header_title {
  font-size: 20px;
  font-weight: bolder;
}

.header_btn {
  border: none;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 10px;
}

.header_btn:hover {
  color: var(--white);
  background: linear-gradient(
    -180deg,
    rgb(69, 68, 68) 0%,
    rgba(234, 230, 230, 0.9) 100%
  );
}

@media only screen and (min-width: 481px) {
  .header_title {
    font-size: 40px;
    font-weight: bolder;
  }
  .header_btn {
    padding: 10px;
    font-size: 14px;
  }
  .header_text {
    bottom: 10px;
    left: 10px;
  }
}
@media only screen and (min-width: 769px) {
  .header_title {
    font-size: 50px;
    font-weight: bolder;
  }
  .header_btn {
    padding: 15px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 992px) {
  .header_title {
    font-size: 64px;
    font-weight: bolder;
  }
  .header_btn {
    padding: 20px 20px;
    font-size: 18px;
  }
}
